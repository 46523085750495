import classNames from 'classnames';
import React from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Spinner from 'reactstrap/lib/Spinner';
import Label from 'reactstrap/lib/Label';

import { NewsletterInterest, NewsletterSubscriber, getNewsletterInterests, subscribe } from '@ttstr/api';

import newsletter from '@ttstr/api/newsletter';
import { CustomInput } from 'reactstrap';
import FormField from '../Form/FormField';
import ValidationAlert from '../Form/ValidationAlert';
import BirthdayPicker from '../Input/BirthdayPicker';
import { useShopConfig } from '../ShopConfig/ShopConfigContext';
import NewsletterDataFields from './NewsletterDataFields';

const defaultInitialValues: NewsletterSubscriber = {
  email: '',
  firstname: '',
  lastname: '',
  country: '',
  birthday_on: null,
  locale: 'de',
};

interface OwnProps {
  onRegistered?(): void;
}

type Props = Readonly<OwnProps>;

const NewsletterRegister: React.FC<Props> = ({ onRegistered }) => {
  const [signupError, setSignupError] = React.useState<string>(null);
  const [interests, setInterests] = React.useState<NewsletterInterest[]>(null);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const shopConfig = useShopConfig();
  const formContext = useForm<NewsletterSubscriber>({
    mode: 'onChange',
    defaultValues: defaultInitialValues,
  });
  const { register, handleSubmit, reset, watch, setValue, formState } = formContext;
  const { isValid, isSubmitting } = formState;

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setInterests(await getNewsletterInterests());
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (values: NewsletterSubscriber) => {
    if ('tnc' in values) {
      delete values.tnc;
    }

    // hardcoding locale to 'de'. Not nice, but locale is required for the api.
    // The value for locale isn't used for anything right now.
    values.locale = 'de';

    try {
      setSignupError(null);
      await subscribe(values);
      if (onRegistered) onRegistered();
    } catch (error) {
      setSignupError(error.message);
    }
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset();
  };

  const birthdayOn = watch('birthday_on');

  return (
    <section>
      <FormProvider {...formContext}>
        <Form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
          <fieldset disabled={isSubmitting}>
            <FormGroup tag="fieldset" className="mb-0">
              <legend>{t(`CUSTOMER.DATA`)}</legend>
              <NewsletterDataFields />

              {!shopConfig.newsletterOverrideValidations?.birthday?.disable && (
                <FormGroup className="mt-0 mb-5">
                  <Label htmlFor="birthday_on">{t(`CUSTOMER.DOB`)}</Label>
                  <BirthdayPicker
                    name="birthday_on"
                    id="birthday_on"
                    value={birthdayOn}
                    onChange={(value) => setValue('birthday_on', value, { shouldValidate: true })}
                    ref={() => register({ name: 'birthday_on' })}
                    required
                  />
                </FormGroup>
              )}

              {loading ? (
                <Spinner color="primary" size="sm" />
              ) : interests?.length > 0 ? (
                <>
                  <fieldset className="form-group">
                    <legend>{t(`CUSTOMER.INTERESTS`)}</legend>
                    <small className="text-muted"> {t('FORM.OPTIONAL')}</small>
                    {interests.map((i) => (
                      <CustomInput
                        key={i.id}
                        id={`newsletter_interest_ids_${i.id}`}
                        name={'newsletter_interest_ids'}
                        innerRef={register({ required: false })}
                        type="checkbox"
                        value={i.id}
                        label={i.title}
                      ></CustomInput>
                    ))}
                  </fieldset>
                </>
              ) : null}

              <FormField
                type="checkbox"
                name="tnc"
                id="tnc"
                /* eslint-disable react/jsx-no-literals */
                label={
                  <Trans i18nKey="REGISTER.TNC">
                    I hereby confirm that I have read and accept the{' '}
                    <Link to="/privacy" target="_blank">
                      Privacy Statement
                    </Link>
                    . I also confirm that I have read and accepted the{' '}
                    <Link to="/terms" target="_blank">
                      Terms and Conditions
                    </Link>
                    .
                  </Trans>
                }
                /* eslint-enable */
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <hr />

            <ValidationAlert className="mb-2" />

            {/* Error should probably be translated with t() + key */}
            {signupError && (
              <p className="text-danger fw-bolder">
                You could not be registered. Maybe this E-Mail is already in use (e.g. when you registered or ordered
                before). Please try again…
                {/* {signupError} */}
              </p>
            )}

            <FormGroup className="d-flex flex-column flex-md-row-reverse justify-content-md-between align-items-md-center">
              <Button type="submit" color="primary" size="lg" className="mb-3" disabled={!isValid}>
                {t('NEWSLETTER.SUBSCRIBE.ACTION')}
                <span className={classNames('spinner-wrapper', { invisible: !isSubmitting })}>
                  <Spinner color="primary" size="sm" />
                </span>
              </Button>
              <Button type="reset" color="link" className="mb-3">
                {t('CUSTOMER.RESET')}
              </Button>
            </FormGroup>
          </fieldset>
        </Form>
      </FormProvider>
    </section>
  );
};

export default React.memo(NewsletterRegister);
